import createDataContext from './createDataContext'

const themeReducer = (state, action) => {
    switch (action.type) {
        case 'setTheme':            
            return {...state, theme: action.payload}
        default:
            return state;
    }
}

const setTheme = (dispatch) => (theme) => {
    dispatch({
        type: 'setTheme',
        payload: theme
    })
}

export const { Context, Provider } = createDataContext(
    themeReducer,
    {setTheme},
    {theme: 'normal'}
)