export function capitalize(name) {
    return name.charAt(0).toUpperCase() + name.slice(1)
};

export function isYoutube(url) {
    const regex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/g;
    return regex.test(url)
}

export function isValidVAT(input) {

    const regex = /^(BE)(\d{10}$)/i;
    return regex.test(input.toUpperCase().replace(/\s/g, ''))
}

export const doTimes = nr => f => {
    if (nr > 0) {
        f()
        doTimes(nr - 1)(f)
    }
}

export const formatPricingListToReducer = plans => {

    return Object.values(plans).map((plan, i) => ({
        index: i,
        lesson_amount: plan.lesson_amount,
        lesson_duration_minutes: plan.lesson_duration_minutes,
        lesson_price_eur: plan.lesson_price.value,
        discount_percentage: plan.discount_percentage,
        is_trial: plan.is_trial
    }))
}

export const formatAvailabilitiesObject = obj => {
    if(obj.length) {
        return Object.values(obj).map(av => av.starts_at)
    }

    return []
}
