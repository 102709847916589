import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useVisitorApi} from 'src/hooks/useApi'
import {experienceLevel, languages} from 'src/data/listData'
import {capitalize} from 'src/util'

const PageContext = React.createContext();

export const PageContextProvider = ({children, value}) => {
    const {i18n} = useTranslation();
    const {getInstrumentsList, getGenresList} = useVisitorApi();
    const [instruments, setInstruments] = useState([]);
    const [genres, setGenres] = useState([]);
    const {lang, matchPath, route} = value;
    let paths;
    if (route) {
        paths = route.paths
    }

    async function fetchInstruments() {
        const listOfInstruments = await getInstrumentsList();
        setInstruments(listOfInstruments.data.data)
    }

    async function fetchGenres(){
        const genresList = await getGenresList();
        setGenres(genresList.data.data)
    }

    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])

    useEffect(() => {
        fetchInstruments()
        fetchGenres()
    }, [lang])

    function createComplexTranslatedSelectList(array) {
        return array.map(item => ({
            label: item.translations[lang],
            value: item.id,
            key: item.key
        })).sort((a, b) => (a.label > b.label) ? 1 : -1)
    }

    function createSimpleTranslatedSelectList(array) {
        return array.map(item => ({label: item.translations[lang], value: item.value}))
    }

    function createSimpleSelectList(array) {
        return array.map(item => ({label: capitalize(item), value: item}))
    }

    return <PageContext.Provider value={{
        lang,
        matchPath,
        paths,
        translatedInstruments: createComplexTranslatedSelectList(instruments),
        translatedExperienceLevel: createSimpleTranslatedSelectList(experienceLevel),
        translatedLanguages: createSimpleTranslatedSelectList(languages),
        genresList: createSimpleSelectList(genres)

    }}>
        {children}
    </PageContext.Provider>
}

export const usePageContext = () => React.useContext(PageContext)
