import createDataContext from './createDataContext';

const removeAvailabilityReducer = (state, action) => {
    switch (action.type) {
        case 'add_availability':
            return [...state, action.payload];
        case 'remove_availability':
            return state.filter(av => av !== action.payload);
        case 'reset':
            return []
        default:
            return state
    }
};

const toBeRemoved = dispatch => (slot) => {
    dispatch({
        type: 'add_availability',
        payload: slot
    })
};

const undo = dispatch => (slot) => {
    dispatch({
        type: 'remove_availability',
        payload: slot
    })
};

const resetToRemove = dispatch => () => {
    dispatch({
        type: 'reset'
    })
};

export const { Provider, Context } = createDataContext(
    removeAvailabilityReducer,
    { toBeRemoved, undo, resetToRemove },
    [],
)