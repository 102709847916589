export const experienceLevel = [
    {
        value: 'none',
        translations: {en: 'None', fr: 'None', nl: 'None'}
    },
    {
        value: 'intermediate',
        translations: {en: 'Beginner', fr: 'Débutant', nl: 'Beginner'}
    },
    {
        value: 'advanced',
        translations: {en: 'Advanced', fr: 'Advanced', nl: 'Advanced'}
    },
    {
        value: 'master',
        translations: {en: 'Master', fr: 'Master', nl: 'Master'}
    },
];

export const languages = [
    {
        value: 'en',
        translations: {
            en: "English",
            nl: "Engels",
            fr: "Anglais"
        }
    },
    {
        value: 'nl',
        translations: {
            en: "Dutch",
            nl: "Nederlands",
            fr: "Néerlandais"
        }
    },
    {
        value: 'fr',
        translations: {
            en: "French",
            nl: "Frans",
            fr: "Français"
        }
    }
];

export const priceList = [
    {label: '25 - 35', value: '25 - 35'},
    {label: '35 - 45', value: '35 - 45'},
    {label: '45 - 55', value: '45 - 55'},
    {label: '55 - 65', value: '55 - 65'},
];

export const teacherAvailableTimeSlots = {
    morning: [
        '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'
    ]
    ,

    afternoon: [
        '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'
    ]
    ,
    evening: [
        '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30'
    ]
};

export const initialPlans = [
    {
        "index": 0,
        "lesson_amount": 1,
        "lesson_duration_minutes": 15,
        "lesson_price_eur": "10",
        "discount_percentage": 0,
        "is_trial": true
    },
    {
        "index": 1,
        "lesson_amount": 1,
        "lesson_duration_minutes": 30,
        "lesson_price_eur": "25",
        "discount_percentage": 0,
        "is_trial": false
    },
    {
        "index": 2,
        "lesson_amount": 3,
        "lesson_duration_minutes": 30,
        "lesson_price_eur": "25",
        "discount_percentage": 10,
        "is_trial": false
    },
    {
        "index": 3,
        "lesson_amount": 5,
        "lesson_duration_minutes": 30,
        "lesson_price_eur": "25",
        "discount_percentage": 15,
        "is_trial": false
    },
    {
        "index": 4,
        "lesson_amount": 10,
        "lesson_duration_minutes": 30,
        "lesson_price_eur": "25",
        "discount_percentage": 20,
        "is_trial": false
    },
    {
        "index": 5,
        "lesson_amount": 1,
        "lesson_duration_minutes": 60,
        "lesson_price_eur": "35",
        "discount_percentage": 0,
        "is_trial": false
    },
    {
        "index": 6,
        "lesson_amount": 3,
        "lesson_duration_minutes": 60,
        "lesson_price_eur": "35",
        "discount_percentage": 10,
        "is_trial": false
    },
    {
        "index": 7,
        "lesson_amount": 5,
        "lesson_duration_minutes": 60,
        "lesson_price_eur": "35",
        "discount_percentage": 15,
        "is_trial": false
    },
    {
        "index": 8,
        "lesson_amount": 10,
        "lesson_duration_minutes": 60,
        "lesson_price_eur": "35",
        "discount_percentage": 20,
        "is_trial": false
    }
];
