// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-about-jsx": () => import("./../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-booking-book-a-teacher-jsx": () => import("./../src/templates/Booking/BookATeacher.jsx" /* webpackChunkName: "component---src-templates-booking-book-a-teacher-jsx" */),
  "component---src-templates-booking-book-trial-jsx": () => import("./../src/templates/Booking/BookTrial.jsx" /* webpackChunkName: "component---src-templates-booking-book-trial-jsx" */),
  "component---src-templates-booking-booking-completed-jsx": () => import("./../src/templates/Booking/BookingCompleted.jsx" /* webpackChunkName: "component---src-templates-booking-booking-completed-jsx" */),
  "component---src-templates-booking-booking-details-review-jsx": () => import("./../src/templates/Booking/BookingDetailsReview.jsx" /* webpackChunkName: "component---src-templates-booking-booking-details-review-jsx" */),
  "component---src-templates-fa-qs-jsx": () => import("./../src/templates/FAQs.jsx" /* webpackChunkName: "component---src-templates-fa-qs-jsx" */),
  "component---src-templates-find-teacher-jsx": () => import("./../src/templates/FindTeacher.jsx" /* webpackChunkName: "component---src-templates-find-teacher-jsx" */),
  "component---src-templates-home-jsx": () => import("./../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-how-it-works-jsx": () => import("./../src/templates/HowItWorks.jsx" /* webpackChunkName: "component---src-templates-how-it-works-jsx" */),
  "component---src-templates-privacy-policy-jsx": () => import("./../src/templates/PrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-jsx" */),
  "component---src-templates-register-jsx": () => import("./../src/templates/Register.jsx" /* webpackChunkName: "component---src-templates-register-jsx" */),
  "component---src-templates-student-account-jsx": () => import("./../src/templates/Student/Account.jsx" /* webpackChunkName: "component---src-templates-student-account-jsx" */),
  "component---src-templates-student-add-details-jsx": () => import("./../src/templates/Student/AddDetails.jsx" /* webpackChunkName: "component---src-templates-student-add-details-jsx" */),
  "component---src-templates-student-confirm-email-jsx": () => import("./../src/templates/Student/ConfirmEmail.jsx" /* webpackChunkName: "component---src-templates-student-confirm-email-jsx" */),
  "component---src-templates-student-edit-jsx": () => import("./../src/templates/Student/Edit.jsx" /* webpackChunkName: "component---src-templates-student-edit-jsx" */),
  "component---src-templates-student-register-jsx": () => import("./../src/templates/Student/Register.jsx" /* webpackChunkName: "component---src-templates-student-register-jsx" */),
  "component---src-templates-teacher-account-jsx": () => import("./../src/templates/Teacher/Account.jsx" /* webpackChunkName: "component---src-templates-teacher-account-jsx" */),
  "component---src-templates-teacher-add-details-jsx": () => import("./../src/templates/Teacher/AddDetails.jsx" /* webpackChunkName: "component---src-templates-teacher-add-details-jsx" */),
  "component---src-templates-teacher-confirm-email-jsx": () => import("./../src/templates/Teacher/ConfirmEmail.jsx" /* webpackChunkName: "component---src-templates-teacher-confirm-email-jsx" */),
  "component---src-templates-teacher-profile-jsx": () => import("./../src/templates/Teacher/Profile.jsx" /* webpackChunkName: "component---src-templates-teacher-profile-jsx" */),
  "component---src-templates-teacher-register-jsx": () => import("./../src/templates/Teacher/Register.jsx" /* webpackChunkName: "component---src-templates-teacher-register-jsx" */),
  "component---src-templates-teacher-registration-completed-jsx": () => import("./../src/templates/Teacher/RegistrationCompleted.jsx" /* webpackChunkName: "component---src-templates-teacher-registration-completed-jsx" */),
  "component---src-templates-teacher-verify-email-jsx": () => import("./../src/templates/Teacher/VerifyEmail.jsx" /* webpackChunkName: "component---src-templates-teacher-verify-email-jsx" */),
  "component---src-templates-terms-and-conditions-jsx": () => import("./../src/templates/TermsAndConditions.jsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-jsx" */)
}

