import { mapKeys, snakeCase, camelCase } from 'lodash'
import { DateTime } from 'luxon'

const BASE_URL = process.env.API_BASE_URL;
export const DATETIME_FORMAT = 'yyyy-LL-dd HH:mm';
export const DATE_FORMAT = 'yyyy-LL-dd';

export function toDateFormat(date) {
    if (! date instanceof DateTime) {
        return null;
    }

    return date.toFormat(DATE_FORMAT);
}

export function fromDateFormat(dateString) {
    if (typeof dateString === 'undefined') {
        return undefined;
    }

    return DateTime.fromISO(dateString, {zone: 'gmt'});
}

export function convertToDateFormat(dateString, initialFormat) {
    if (typeof dateString === 'undefined') {
        return undefined;
    }

    return DateTime.fromFormat(dateString, initialFormat).toFormat(DATE_FORMAT);
}

export function toDateTimeFormat(date) {
    if (! date instanceof DateTime) {
        return null;
    }

    return date.toFormat(DATETIME_FORMAT);
}

export function fromDateTimeFormat(dateString) {
    if (typeof dateString === 'undefined') {
        return undefined;
    }

    return DateTime.fromFormat(dateString, DATETIME_FORMAT);
}

export function convertToDateTimeFormat(dateString, initialFormat) {
    if (typeof dateString === 'undefined') {
        return undefined;
    }

    return DateTime.fromFormat(dateString, initialFormat).toFormat(DATETIME_FORMAT);
}

function endpoint(endpoint) {
    return `${BASE_URL}${endpoint}`;
}

export function transformRequest(request) {
    return mapKeys(request, (value, key) => snakeCase(key));
}

export function transformResponse(response) {
    return mapKeys(response, (value, key) => camelCase(key));
}

export const getTokenEndpoint = endpoint('/oauth/token');
export const verifyEmailEndpoint = endpoint('/api/verify-email');
export const registerAsAStudentEndpoint = endpoint('/api/register-as-student');
export const addStudentDetailsEndpoint = endpoint('/api/me/student-profile');
export const registerAsATeacherEndpoint = endpoint('/api/register-as-teacher');
export const updateTeachersEndpoint = endpoint('/api/me/teacher-profile');
export const getInstrumentsListEndpoint = endpoint('/api/instruments');
export const getGenresListEndpoint = endpoint('/api/genres');
export const getUser = endpoint('/api/me');
export const editUserEndpoint = endpoint('/api/me');
export const editRegisterAsAStudentEndpoint = endpoint('/api/register-as-student/edit');
export const editRegisterAsATeacherEndpoint = endpoint('/api/register-as-teacher/edit');
export const getTeachers = endpoint('/api/teachers');
export const getHighlightedTeachersEndpoint = endpoint('/api/teachers/highlighted');
export const updatePricing = endpoint('/api/me/pricing');
export const editAvailabilities = endpoint('/api/me/availabilities');
export const bookLesson = endpoint('/api/lessons/book');
export const resendVerificationEmail = endpoint('/api/verify-email/resend');
export const forgotPasswordEndpoint = endpoint('/api/forgot-password');
export const resetPasswordEndpoint = endpoint('/api/reset-password');
export const getTeacherLessonsEndpoint = endpoint('/api/me/lessons');
export const rescheduleLessonTeacherEndpoint = (id) => endpoint(`/api/lessons/${id}/request-reschedule`);
export const rescheduleLessonStudentEndpoint = (id) => endpoint(`/api/lessons/${id}/reschedule`);
export const connectZoomEndpoint = endpoint('/connect/zoom');
export const editProfilePictureEndpoint = endpoint('/api/me/picture');
