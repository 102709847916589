import createDataContext from 'src/context/createDataContext'

const profileReducer = (state, action) => {
    switch (action.type) {
        case "addError":
            return {...state, errorMessage: action.payload};
        case "clearErrorMessage":
            return {...state, errorMessage: ""};
        case "login":
            return {...state, profile: action.payload};
        case "logout":
            return {...state, profile: null, accessToken: null, refreshToken: null};
        case "toggleLogin":
            return {...state, showLogin: !state.showLogin, showForgotPassword: false, showResetPassword: false};
        case "hideLogin":
            return {...state, showLogin: false, showForgotPassword: false, showResetPassword: false};
        case "toggleForgotPassword":
            return {...state, showForgotPassword: !state.showForgotPassword, showLogin: false, showResetPassword: false};
        case "toggleResetPassword":
            return {...state, showResetPassword: !state.showResetPassword, showLogin: false, showForgotPassword: false};
        case 'setTokens':
            const { accessToken, refreshToken } = action.payload;
            return {...state, accessToken, refreshToken};
        case 'showResetPassword':
            return {...state, showResetPassword: true, showLogin: false, showForgotPassword: false};
        default:
            return state
    }
}

const setProfile = dispatch => (profile) => {
    dispatch({
        type: 'login',
        payload: profile
    })
}

const forgetProfile = dispatch => () => {
    dispatch({
        type: 'logout'
    })
}

const setOAuthTokens = dispatch => ({ accessToken, refreshToken }) => {
    dispatch({
        type: 'setTokens',
        payload: {
            accessToken: accessToken,
            refreshToken: refreshToken
        }
    })
}

const forgetTokens = dispatch => () => {
    dispatch({
        type: 'setTokens',
        payload: {
            accessToken: null,
            refreshToken: null
        }
    })
}

const hideLogin = dispatch => () => {
    dispatch({
        type: 'hideLogin'
    })
}

const toggleLogin = dispatch => () => {
    dispatch({
        type: 'toggleLogin'
    })
}

const toggleForgotPassword = dispatch => () => {
    dispatch({
        type: 'toggleForgotPassword'
    })
}

const toggleResetPassword = dispatch => () => {
    dispatch({
        type: 'toggleResetPassword'
    })
}

const showResetPassword = dispatch => () => {
    dispatch({
        type: 'showResetPassword'
    })
}


let persisted = {
    data: 'auth'
}

export const {Provider, Context} = createDataContext(
    profileReducer,
    {toggleLogin, setProfile, forgetProfile, setOAuthTokens, forgetTokens, toggleForgotPassword, toggleResetPassword, showResetPassword, hideLogin},
    {errorMessage: '', profile: null, showLogin: false, showForgotPassword: false, showResetPassword: false, accessToken: null, setRefreshToken: null},
    {...persisted}
)
