import React, {useReducer } from 'react'
import createPersistedReducer from 'use-persisted-reducer'

// Automate Context creation
export default (reducer, actions, initialState, persisted) => {
    const Context = React.createContext();

    let Provider;
    if (persisted) {
        const usePersistedReducer = createPersistedReducer(persisted.data)
        Provider = ({children}) => {
            const [state, dispatch] = usePersistedReducer(reducer, initialState);
            return useProvider({children}, state, dispatch, actions, Context)
        }
    } else {
        Provider = ({children}) => {
            const [state, dispatch] = useReducer(reducer, initialState);
            return useProvider({children}, state, dispatch, actions, Context)
        }
    }

    return {Context, Provider}
}

function useProvider({children}, state, dispatch, actions, Context) {
    const boundActions = {};
    for (let key in actions) {
        boundActions[key] = actions[key](dispatch)
    }

    return (
        <Context.Provider
            value={{
                state,
                ...boundActions,
            }}
        >
            {children}
        </Context.Provider>
    )
}