import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import locales from 'i18n/locales'
import en from 'src/i18n/translations/en'
import fr from 'src/i18n/translations/fr'
import nl from 'src/i18n/translations/nl'

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: process.env.NODE_ENV === 'development',
        initImmediate: false,
        returnObjects: true,
        preload: locales.supported,
        whitelist: locales.supported,
        fallbackLng: locales.fallback,
        resources: {
            en: { translation: en },
            fr: { translation: fr },
            nl: { translation: nl },
        },
        detection: {
            order: ['path', 'navigator'],
        }
    });

export default i18n;
