import React from 'react'
import {I18nextProvider} from 'react-i18next'
import i18next from 'src/i18n/i18next'
import {Provider as ProfileProvider} from 'src/context/ProfileContext'
import {Provider as ThemeProvider} from 'src/context/ThemeContext'
import {Provider as BookingProvider} from 'src/context/BookingContext'
import {Provider as AddAvailabilityProvider} from 'src/context/AddAvailabilityContext'
import {Provider as RemoveAvailabilityProvider} from 'src/context/RemoveAvailabilityContext'
import {VisitorApiProvider} from 'src/context/VisitorApiContext'
import {PageContextProvider} from 'src/context/PageContext'

export const wrapRootElement = ({element}) => (
    <I18nextProvider i18n={i18next}>
        <ProfileProvider>
            <VisitorApiProvider>
                <BookingProvider>
                    <AddAvailabilityProvider>
                        <RemoveAvailabilityProvider>
                            <ThemeProvider>
                                {element}
                            </ThemeProvider>
                        </RemoveAvailabilityProvider>
                    </AddAvailabilityProvider>
                </BookingProvider>
            </VisitorApiProvider>
        </ProfileProvider>
    </I18nextProvider>
)

export const wrapPageElement = ({element, props}) => {
    return <PageContextProvider value={props.pageContext}>
        {element}
    </PageContextProvider>
}
