import  createDataContext from 'src/context/createDataContext'
import { DateTime} from 'luxon'

const initialState = {
    teacher: null,
    teacherID: null,
    teacherAvailability: [],
    instrument: null,
    duration: null,
    nrOfLessons: null,
    lessonsToSchedule: [],
    comments: '',
    price: null,
    lessonPlan: null,
    enabledDays: [],
    selectedDay: null,
    currentTimeslotsAvailable: [],
    isTrial: false
}

const bookingReducer = (state, action) => {
    switch (action.type) {
        case 'update-booking':
            return {...state, ...action.payload}
        case 'resetSelectedDay':
            return { ...state, selectedDay: null}
        case 'setSelectedDay':
            return { ...state, selectedDay: action.payload}
        case 'resetCurrentTimeslotsAvailable':
            return { ...state, currentTimeslotsAvailable: []}
        default:
            return state
    }
}

const changeBooking = dispatch =>  (bookingDetails) => {
    dispatch({
        type: 'update-booking',
        payload: bookingDetails
    })
}

const resetBookingData = dispatch => () => {
    dispatch({
        type: 'update-booking',
        payload: initialState
    })
}

const resetSelectedDay = dispatch => () => {
    dispatch({
        type: 'resetSelectedDay'
    })
}

const setSelectedDay = dispatch => (day) => {
    dispatch({
        type: 'setSelectedDay',
        payload: day
    })
}

const resetCurrentTimeslotsAvailable = dispatch => () => {
    dispatch({
        type: 'resetCurrentTimeslotsAvailable'
    })
}

let persisted = {
    data: 'booking'
}

export const { Provider, Context } = createDataContext(
    bookingReducer,
    { changeBooking, resetBookingData, resetSelectedDay, resetCurrentTimeslotsAvailable, setSelectedDay },
    initialState,
    {...persisted}
)


