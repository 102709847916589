import React, {createContext, useContext, useEffect} from 'react'
import axios, {get, patch, post, put} from 'axios'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import {
    addStudentDetailsEndpoint,
    bookLesson,
    convertToDateFormat,
    editAvailabilities,
    getGenresListEndpoint,
    getInstrumentsListEndpoint,
    getTeachers,
    getTokenEndpoint,
    getUser,
    registerAsAStudentEndpoint,
    registerAsATeacherEndpoint,
    resendVerificationEmail,
    transformRequest,
    updatePricing,
    updateTeachersEndpoint,
    verifyEmailEndpoint,
    forgotPasswordEndpoint,
    resetPasswordEndpoint,
    getTeacherLessonsEndpoint,
    rescheduleLessonTeacherEndpoint,
    rescheduleLessonStudentEndpoint,
    connectZoomEndpoint,
    editUserEndpoint,
    editRegisterAsAStudentEndpoint,
    editRegisterAsATeacherEndpoint,
    editProfilePictureEndpoint,
    getHighlightedTeachersEndpoint,
} from 'src/api';

const CLIENT_ID = process.env.API_CLIENT_ID;
const CLIENT_SECRET = process.env.API_CLIENT_SECRET;

const VisitorApiContext = createContext({});
const {Provider} = VisitorApiContext;

export function VisitorApiProvider({children}) {
    let {state: {accessToken, profile}, setOAuthTokens, setProfile} = useContext(ProfileContext);

    useEffect(function redirectToAuthenticationSection() {
        if (accessToken) {
            //console.log('to authenticated screen')
        }
    }, [accessToken]);

    async function getToken(values) {
        const response = await post(getTokenEndpoint, {
            grant_type: 'password',
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            ...values,
        });

        const {access_token, refresh_token} = response.data;

        setOAuthTokens({
            accessToken: access_token,
            refreshToken: refresh_token,
        });

        await setUser(access_token)
        return response;
    }

    async function forgotPassword(values) {
        return await post(forgotPasswordEndpoint, transformRequest(values));
    }

    async function resetPassword(values) {
        const response = await post(resetPasswordEndpoint, transformRequest(values));
        return response
    }

    async function getInstrumentsList() {
        return await get(getInstrumentsListEndpoint);
    }

    async function getGenresList() {
        return await get(getGenresListEndpoint);
    }

    async function registerStudent(values) {
        return await post(registerAsAStudentEndpoint, transformRequest(values));
    }

    async function getAllTeachers(filters) {
        const params = {...filters};

        return await get(getTeachers, {params})
    }

    async function getSingleTeacher(id) {
        return await get(`${getTeachers}/${id}`)
    }

    async function addStudentDetails(values) {
        const {data} = await put(addStudentDetailsEndpoint, transformRequest(values), {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        });

        const updated = data.data;
        profile.student_profile = {...updated}
        setProfile(profile)
    }

    async function editUser(values) {
        const response = await put(editUserEndpoint, transformRequest(values), {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        });

        setProfile(response.data.data)

        return response
    }

    async function editRegisterStudent(values) {
        const response = await put(editRegisterAsAStudentEndpoint, transformRequest(values), {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        });

        setProfile(response.data.data)

        return response
    }

    async function editRegisterTeacher(values) {
        const response = await put(editRegisterAsATeacherEndpoint, transformRequest(values), {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            }
        });

        setProfile(response.data.data)

        return response
    }

    async function registerTeacher(values) {
        const val = transformRequest(values);
        const data = new FormData();
        for (const key in val) {
            data.append(key, val[key]);
        }
        return await post(registerAsATeacherEndpoint, data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    async function verifyEmail(obj) {
        try {
            await post(verifyEmailEndpoint, transformRequest(obj),
                {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                }
            );
            await setUser(accessToken)
        } catch (error) {
            const { response } = error
            return response
        }

    }

    async function resendVerification(redirectUrl) {
        try {
            const res = await post(resendVerificationEmail, {
                    redirect_url: redirectUrl
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                })
            await setUser(accessToken)
            return res.data
        } catch (error) {
            const {response} = error;
            return response.data
        }
    }

    async function updateTeachersProfile(values) {
        const {data} = await put(updateTeachersEndpoint, transformRequest(values),
            {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        );

        const updated = data.data
        profile.teacher_profile = {...updated}
        setProfile(profile)
    }

    async function updatePaymentMethod(values) {
        const instruments = profile.teacher_profile.instruments.map(({id}) => id);

        const profileData = {...profile.teacher_profile, ...values, instruments: [...instruments]}
        const {data} = await put(updateTeachersEndpoint, transformRequest(profileData),
            {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        );
        const updated = data.data
        profile.teacher_profile = {...updated}
        setProfile(profile)
    }

    async function updatePrices(values) {
        const {data} = await put(updatePricing, {plans: values},
            {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })

        const {plans} = data.data
        profile.teacher_profile.lesson_plans = {...plans}
        setProfile(profile)
        return true
    }

    async function setUser(access_token) {
        const {data} = await get(getUser,
            {
                headers: {
                    'Authorization': 'Bearer ' + access_token
                }
            }
        );

        setProfile(data.data)
    }

    async function getMe() {
        const {data} = await get(getUser,
            {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }
        );

        setProfile(data.data)
    }

    async function getTeacherAvailabilities({id, after, before}) {
        const params = {
            before,
            after
        };

        const {data} = await get(`${getTeachers}/${id}/availabilities`,
            {
                params,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
        return data.data
    }

    async function getTeacherLessons({ before, after, page }) {
        const params = {
            before,
            after,
            page
        };

        const response = await get(getTeacherLessonsEndpoint,
            {
                params,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
        return response.data
    }

    async function getHighlightedTeachers() {
        const response = await get(getHighlightedTeachersEndpoint,
            {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
        return response.data
    }


    async function addTeacherAvailabilities(availabilities) {

        return await patch(editAvailabilities, {availabilities},
            {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
    }

    async function deleteTeacherAvailabilities(availabilities) {
        axios.delete(editAvailabilities,
            {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: {
                    availabilities
                }
            })
    }

    async function bookALesson(booking) {
        try {
            const {data} = await post(bookLesson, transformRequest(booking),
                {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                })
            return data.data
        } catch (error) {
            const {response} = error;

            return response.data.errors
        }
    }

    async function rescheduleLessonTeacher(id, values) {
       return await post(rescheduleLessonTeacherEndpoint(id), transformRequest(values),
            {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
        });
    }

    async function rescheduleLesson (id, values) {
        try{
            return await post(rescheduleLessonStudentEndpoint(id), transformRequest(values),
                {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                });
        }catch(error){
            return {error: true}
        }
    }

    async function connectZoomAccount (obj) {
        return await get(connectZoomEndpoint, {
            params: obj,
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            }
        })
    }

    async function changeProfilePicture(values) {
        const val = transformRequest(values);
        const data = new FormData();
        for (const key in val) {
            data.append(key, val[key]);
        }
        const response = await post(editProfilePictureEndpoint, data, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    }

    return (
        <Provider value={{
            convertToDateFormat,
            getToken,
            getInstrumentsList,
            getGenresList,
            registerStudent,
            addStudentDetails,
            registerTeacher,
            verifyEmail,
            updateTeachersProfile,
            setUser,
            getAllTeachers,
            getSingleTeacher,
            updatePaymentMethod,
            updatePrices,
            getTeacherAvailabilities,
            addTeacherAvailabilities,
            deleteTeacherAvailabilities,
            rescheduleLessonTeacher,
            bookALesson,
            resendVerification,
            getTeacherLessons,
            forgotPassword,
            resetPassword,
            rescheduleLesson,
            connectZoomAccount,
            getMe,
            editUser,
            editRegisterStudent,
            editRegisterTeacher,
            changeProfilePicture,
            getHighlightedTeachers
        }}>
            {children}
        </Provider>
    );
}

export default VisitorApiContext;
